import topic from '../topic';

export const mupcAPI = {
  getMupcs: topic.mupcTopic + '/getMupcs',
  getMupcById: topic.mupcTopic + '/getMupcById',
  addMupc: topic.mupcTopic + '/addMupc',
  editMupc: topic.mupcTopic + '/editMupc',
  destroyMupcByIds: topic.mupcTopic + '/destroyMupcByIds',
  getUserMupc: topic.mupcTopic + '/getUserMupc',
  readMurfCard: '/api/muop/readMurfCard'
};
